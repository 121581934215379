
export const LOCALES = {
  PRIMARY_LOCALE: "en-GB",
  SECONDARY_LOCALE: "it-IT"
}

export const ROLES = {
  DIRECTOR: "etica_dt",
  SUPERVISOR: "abc_direttore",
  USER: "Utente_Piattaforma",
  MANTAINER: "Capo Manutentore",
  RUP: "RUP"
};

export const ROLES_ID = {
  DIRECTOR: 1,
  ISPECTOR: 2,
  DO: 3,
  DL: 4,
  RUP: 5,
  MANTAINER: 6
}

export const KEYCLOAK = {
  URL: "https://abc.unifiedcommandandcontrolsystem.it/auth",
  REALM: "abc",
  CLIENTID: "uui",
}

export const COREAPI_ENDPOINTS = {
  URL: "https://abc.unifiedcommandandcontrolsystem.it/api",
  PATH_GDL: "/giornaliLavoro",
  PATH_CONTRATTI: "/contratti",
  PATH_USERS: "/utenti",
  PATH_ODL: "/ordini-lavori",
  PATH_ADDETTI: "/addetti",
  PATH_POI: "/impianti",
  PATH_ATTACHMENTS: "/allegati",
  PATH_POLI: "/poli",
  PATH_MESSAGES: "/firebase"

}

export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "DD/MM/YYYY";
export const WEBSOCKET_URL = "wss://abc.unifiedcommandandcontrolsystem.it/ws";
export const DEFAULT_CONTRACT_ID = 10;
