import axios from "axios";
import { COREAPI_ENDPOINTS } from "../../config";
import { IUser } from "../../types";
import { launchNotification } from ".";

export async function getUsers() {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS);
}

export async function getUsersByRole(role: string) {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS, { params: {
    role: role
  } });
}

export async function getUserInfo() {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS + '/info');
}

export async function createUser(user: IUser) {
  try {
    await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS, user);
    launchNotification("SUCCESSO", "Utente creato con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la creazione dell'utente.", "danger");
  }
}

export async function updateUser(user: IUser) {
  try {
    await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS+ "/" + user.id, user);
    launchNotification("SUCCESSO", "Utente modificato con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la modifica dell'utente", "danger");
  }
}

export async function resetPasswordUser(user: IUser) {
  try {
    await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS + "/" + user.id + "/password-reset", user);
    launchNotification("SUCCESSO", "Password reimpostata con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la reimpostazione della password.", "danger");
  }
}

export async function deleteUser(user: IUser) {
  try {
    await axios.delete(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_USERS + "/" + user.id);
    launchNotification("SUCCESSO", "Utente rimosso con successo.",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la rimozione dell'utente.", "danger");
  }
}
