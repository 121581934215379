import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import { IntlProvider } from 'react-intl';
import messages from '../../config/messages'

interface IIntlContextProvider {
  locale: string,
  setLocale: Dispatch<SetStateAction<string>>
}

const IntlContext = createContext<IIntlContextProvider | null>(null)


const IntlContextProvider = ({ children }: { children: React.ReactNode }) => {

    const [locale, setLocale] = useState('it-IT')


    return <IntlContext.Provider
        value={{ locale : locale, setLocale : setLocale }}>
             <IntlProvider messages={messages[locale]} locale={locale}>
                {children}
            </IntlProvider>
    </IntlContext.Provider>

}
/**
 * Helper function to get Custom Intl Context
 * @returns {{lang, setLang}} to get/set locale language
 */
const useIntlContext = () => {
    const context = useContext(IntlContext);
    if (!context) {
        throw new Error('useIntl must be used within a intlProvider');
    }
    return context;
}
export { IntlContextProvider, useIntlContext };
