const GDL_STATES: {[key: string]: string} = {
  REDACT: "REDAZIONE",
  WORKING: "LAVORAZIONE",
  APPROVAL: "APPROVAZIONE LAVORAZIONE",
  VERIFICATION: "VERIFICA ESITO",
  APPROVAL_GDL: "APPROVAZIONE GDL",
  CLOSURE: "CHIUSURA GDL",
  CLOSED: "CHIUSO"
}

export const GDL_STATES_REVERSE_KEYS: {[key: string]: string} = {
  "REDAZIONE": 'redact',
  "LAVORAZIONE": 'working',
  "APPROVAZIONE LAVORAZIONE": 'approval',
  "VERIFICA ESITO": 'verification',
  "APPROVAZIONE GDL": "approval_gdl",
  "CHIUSURA GDL": 'closure',
  "CHIUSO": 'closed',
};
export default GDL_STATES;
