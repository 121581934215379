import React from 'react';
import { useUser } from "../../context/user";
import { Redirect, Route } from 'react-router-dom';
import { CFade } from '@coreui/react'

interface IAuthorizedRouteProps {
  component: any;
  roles: number[];
  [property: string]: any;
}

export default function AuthorizedRoute({ component: Component, roles, ...rest}: IAuthorizedRouteProps) {

    const { userRoles } = useUser();

    const isAutherized = (roles: number[]) => userRoles.some(r => roles.includes(r));

    return (
        <Route
            {...rest}
            render={props => {
                return isAutherized(roles)
                    ? <CFade><Component {...props}/></CFade>
                    : <Redirect to='/' />
            }}
        />

    )
}
