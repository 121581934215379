import React from "react";
import { CIcon } from "@coreui/icons-react";

export const localeFlag = (locale: string): any => {
    if (locale === "en-GB") { return <CIcon name="cif-gb" /> }
    else if (locale === "it-IT") { return <CIcon name="cif-it" /> }
    else return null
}

/**
 * @description Helper function used in forms to trigger state update. It recreates the object on every input change
 * @params {string} property: Name of the property that will be changed
 * @params {any} value: New value
 * @returns {any} A new object with the same parameters
 */
export const handlePropertyChange = (object: {[properties: string]: any}, property: string, value: any): any => {
  object[property] = value;
  return {...object};
}

/**
 * @description Helper function used in forms to update more than one property in a single setState.
 * @params {string[]} properties: Name of the property that will be changed
 * @params {[property: string]: any} value: Map of values
 * @returns {any} A new object with the same parameters
 */
export const handlePropertiesChange = (object: {[properties: string]: any}, properties: string[], values: {[property: string]: any}): any => {
  for (const property of properties) {
    object[property] = values[property];
  }
  return {...object}
}

/**
 * @description Given an array, it will create a hashMap object
 * @params {any[]} array
 * @params {string} key: The name of the property that will be mapped. It must be present in every element of the array
 * @params {any} mapper: Function that will map each element according to the user needs
 * @params {boolean} isNotArray: By default each value of the hashmap will be an array. If you want a single value per key set this to true.
 * @returns {[key: string]: any}
 */

export const mapWithKeys = (array: any[], key: string, mapper: any, isNotArray?: boolean): {[key: string]: any} => {
  const object: {[key: string]: any} = {}
  array.forEach((element) => {
    const elementKey = element[key];
    if (object[elementKey] === undefined) object[elementKey] = [];
    if (isNotArray) {
      object[elementKey] = mapper(element);
    }
    else {
      object[elementKey].push(mapper(element));
    }
  });
  return object;
}

export const readFileAsPromise = (file: any) => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
  })
)

function base64ToArrayBuffer(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) { bytes[i] = binary_string.charCodeAt(i); } return bytes.buffer;
}

/**
 * @description This function will generate a blob URLobject from a base64
 * @param {string} base64
 * @returns {string} blob url ready to be used for downloads or displaying
 */
export function blobFromBase64(base64: string, isPdf?: boolean): string {
  const pdfBin = base64ToArrayBuffer(base64.split(";base64,")[1] ?? base64.split(";base64,")[0]);
  const blob = new Blob([pdfBin], { type: isPdf ? 'application/pdf' : 'image/*' });
  return URL.createObjectURL(blob);
}


function showImageBlank(base64: string) {
  const image = base64.match(';base64,') ? base64 : 'data:image/*;base64,' + base64;
  const a = window.open('', "_blank");
  a?.document.write('<img src="' + image + '" />');
}

function showPdfBlank(base64: string) {
  window.open(blobFromBase64(base64, true), "_blank");
}

export function showFileBlank(base64: string, isPdf: boolean) {
  isPdf ? showPdfBlank(base64) : showImageBlank(base64);
}

/**
 * @description Prompts a download for a file
 * @param {string} base64
 * @param {string} title: Filename will be [title].pdf
 */
export function downloadPdf(base64: string, title: string) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.href = blobFromBase64(base64, !!title.match(".pdf"));
  a.download = title;
  a.click();
}

export const parseStringWithLineBreak = (str: string): React.ReactNode => str.split("\n").map(s => <>{s}<br/></>)

export const getTimeLapseFromUnix = (unixDate: number): {days: number, hours: number, minutes: number} | null => {
  if (unixDate === 0) return null;
  const days = Math.floor(unixDate / (60 * 60 * 24));
  if (days > 0) unixDate %= (60 * 60 * 24);
  const hours = Math.floor(unixDate / (60 * 60));
  if (hours > 0) unixDate %= (60 * 60);
  const minutes = Math.floor(unixDate / 60);
  return { days, hours, minutes }
}

export const reduceEntity = (object: any[], startingValueInit?: () => any, keyValueInit?: (i: any) => string): {[id: string]: any} => {
  return object.reduce(((previousValue: any, currentValue: any) => {
    const key = keyValueInit ? keyValueInit(currentValue) : currentValue.id!;
    previousValue[key] = startingValueInit ? startingValueInit() : 0;
    return previousValue;
  }) as any, {}) as {[id: string]: number};
}


export * from "./attachmentApi";
export * from "./notifications";
export * from './giornaliLavoroApi';
export * from "./contrattiApi";
export * from "./userApi";
export * from "./ordiniLavoriApi";
export * from "./addettiApi";
export * from "./poiApi";
export * from "./poliApi";
export * from "./messageApi";
