import axios from 'axios';
import { IGiornaleLavoro, IAttivita } from "../../types";
import { COREAPI_ENDPOINTS } from "../../config";
import { launchNotification } from ".";
import { Moment } from "moment";

export function getGiornaliLavoro({start_date, end_date}: {start_date: Moment, end_date: Moment}) {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL, {
    params: {
      from: start_date.unix(),
      to: end_date.unix()
    },
    timeout: 30 * 1000
  })
}

export function getGiornaleLavoroById(id: number) {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/" + id);
}

export function getGiornaliLavoroByOdL(id: string, {start_date, end_date}: {start_date: Moment, end_date: Moment}) {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL, {
    params: {
      ordine_lavoro: id,
      from: start_date.unix(),
      to: end_date.unix()
     }
  })
}

export async function createGiornaleLavoro(gdl: IGiornaleLavoro): Promise<any> {
  try {
    gdl.attivita_eseguite = gdl.attivita_eseguite?.filter((a: IAttivita) => !(a.isremoved && !a.id));
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL, gdl);
    launchNotification("SUCCESSO", "Giornale dei Lavori creato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la creazione del giornale dei lavori", "danger");
    throw new Error("");
  }
}

export async function updateGiornaleLavoro(gdl: IGiornaleLavoro): Promise<any> {
  try {
    gdl.attivita_eseguite?.forEach((a: IAttivita) => a.isdone = true);
    gdl.attivita_eseguite = gdl.attivita_eseguite?.filter((a: IAttivita) => !(a.isremoved && !a.id));
    const response = await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/" + gdl.id, gdl);
    launchNotification("SUCCESSO", "Il giornale dei lavori è stato completato con successo", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante il completamento del giornale dei lavori", "danger");
    throw new Error("");

  }
}

export async function deleteGiornaleLavoro(gdl: IGiornaleLavoro): Promise<any> {
  try {
    const response = await axios.delete(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL, {data: gdl});
    launchNotification("SUCCESSO", "Il giornale dei lavori è stato rimosso con successo", "success");
    return response;
  } catch {
    launchNotification("ERRORE", "Si è verificato un errore durante la rimozione del giornale dei lavori", "danger");
  }
}

export async function confirmGiornaleLavoro(id: number, gdl?: IGiornaleLavoro): Promise<any> {
  try {
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/" + id + "/conferma", gdl);
    launchNotification("SUCCESSO", "Giornale dei Lavori firmato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la firma del giornale dei lavori", "danger");
    throw new Error("");
  }
}

export async function revokeGiornaleLavoro(gdl: IGiornaleLavoro): Promise<any> {
  try {
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/" + gdl.id + "/revoca");
    launchNotification("SUCCESSO", "Giornale dei Lavori revocato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la revoca del giornale dei lavori", "danger");
    throw new Error("");
  }
}

export async function multiConfirmGiornaliLavoro(ids: number[]): Promise<any> {
  try {
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/conferma-multipla", { ids });
    launchNotification("SUCCESSO", "Giornali dei Lavori firmati con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la firma dei giornali dei lavori", "danger");
    throw new Error("");
  }
}

export async function multiRevokeGiornaleLavoro(ids: number[]): Promise<any> {
  try {
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_GDL + "/revoca-multipla", { ids });
    launchNotification("SUCCESSO", "Giornali dei Lavori revocati con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la revoca dei giornali dei lavori", "danger");
    throw new Error("");
  }
}
