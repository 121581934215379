import React, { useState, useEffect } from "react";
import { useUser } from "../../context/user";
import { UIHeader, UIFooter } from "../../containers";
import GCard from "../../components/GCard";
import GButton from "../../components/GButton";
import Loading from "../../components/Loading";
import { InformativaDati, TOS, TOSTitle, InformativaDatiTitle } from "./tos"

interface IConsentFormProps {
  onClick: () => void;
}

const ConsentForm = ({onClick}: IConsentFormProps) => {
  return (
    <div className="d-flex full-width justify-content-center align-items-center">
      <GButton messageKey="Accetta" color="primary" onClick={onClick} />
    </div>
  )
}

const bodies = [<TOS />, <InformativaDati />];
const titles = [TOSTitle, InformativaDatiTitle];

const Consent = () => {

  const { acceptConsent } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleClick = () => {
    if (currentPage === 1) {
      acceptConsent();
    }
    else {
      setCurrentPage(1);
    }
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true);
  }, [currentPage])

  return (
    <div className="c-app c-default-layout">
    <div className="c-wrapper">
      <UIHeader />
        <div className="c-body">
          <div className="consent-page">
            {
              isLoading ?
              <Loading />
              :
              <GCard
                title={titles[currentPage]}
                className="main-card"
                footer={<ConsentForm onClick={handleClick}/>}
              >
                {
                  bodies[currentPage]
                }
              </GCard>
            }
          </div>
        </div>
      <UIFooter />
    </div>
    </div>
  )
}

export default Consent;
