import './scss/style.scss';
import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import AppRouter from "./routes/";
import { icons } from "./assets/icons";

//Authentication
import { KeycloakProvider } from '@react-keycloak/web'
import keycloak from './config/keycloak'

//Localization
import { IntlContextProvider } from './context/intl';

import { SidebarProvider } from "./context/sidebar";

import { UserProvider } from "./context/user";

import { SocketProvider } from "./context/socket";

import { LocationProvider } from "./context/location";


import ReactNotifications from "react-notifications-component";

// We need to inject this property set to use icons. React apparently does not exprect this property hence this temporary conversion to any.
(React as any).icons = icons;

export const App = () => {
  return (
    <>
    <KeycloakProvider keycloak={keycloak}>
      <IntlContextProvider>
        <SidebarProvider>
          <LocationProvider>
            <UserProvider>
              <SocketProvider>
                <ReactNotifications />
                <AppRouter />
              </SocketProvider>
            </UserProvider>
          </LocationProvider>
        </SidebarProvider>
      </IntlContextProvider>
    </KeycloakProvider>
    </>
  );
}

export default App;
