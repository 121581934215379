import React, { Suspense } from 'react'
import {
  Redirect,
  Switch
} from 'react-router-dom'
import { CContainer } from '@coreui/react'

// routes config
import routes from '../routes'
import AuthorizedRoute from '../components/AuthorizedRoute'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheContent = () => {
  return (
    <main className="c-main bg-color-primary-darkest">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <AuthorizedRoute roles={route.role} path={route.path} component={route.component} name={route.name} key={idx} exact={route.exact} />
              )
            })}
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
