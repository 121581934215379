import React, { useState, useEffect, useContext, createContext } from "react";
import { IUser } from "../../types";
import { useKeycloak } from "@react-keycloak/web";
import { getUserInfo } from "../../components/helpers";
import { ROLES_ID } from "../../config";
import sha256 from "crypto-js/sha256";


interface IUserProvider {
  user: IUser | null;
  userRoles: number[];
  isLoading: boolean;
  isAuth: boolean | null;
  isError: boolean;
  hasConsent: boolean | null;
  acceptConsent: () => void;
}

const UserContext = createContext<IUserProvider | null>(null);

const UserProvider = ({children}: { children: React.ReactNode }) => {

  const [keycloak, initialized] = useKeycloak();

  const [user, setUser] = useState<IUser | null>(null);
  const [userRoles, setUserRoles] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasConsent, setHasConsent] = useState<boolean | null>(null);

  const acceptConsent = () => {
    const hashedId = sha256(user?.kcuser ?? "").toString()
    window.localStorage.setItem(hashedId, "true");
    setHasConsent(true);
  }

  useEffect(() => {
    if (initialized && keycloak.authenticated && user === null) {
      getUserInfo().then(({data}) => setUser(data?.user)).catch(() => setIsError(true));
    }
  }, [initialized, keycloak.authenticated])

  useEffect(() => {
    if (user !== null) {
      const hashedId = sha256(user.kcuser ?? "").toString()
      const consentValue = window.localStorage.getItem(hashedId) === "true";
      const rolesToHave: number[] = [ROLES_ID.DIRECTOR, ROLES_ID.ISPECTOR, ROLES_ID.DL, ROLES_ID.DO, ROLES_ID.RUP];
      const userRoles: number[] = user.roles?.map(({roleid}) => roleid) ?? [];
      const isAllowed: boolean = rolesToHave.some(r => userRoles.includes(r));
      setUserRoles(userRoles);
      setIsAuth(isAllowed);
      setHasConsent(consentValue);
    }
  }, [user])

  useEffect(() => {
    setIsLoading(isAuth === null && hasConsent === null);
  }, [isAuth, hasConsent])

  return (
    <UserContext.Provider value={{ user, isLoading, isAuth, userRoles, isError, hasConsent, acceptConsent }}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error("useUser must be used with UserProvider");
  }
  return context;
}

export { useUser, UserProvider };
