
import Keycloak from 'keycloak-js'
import { KEYCLOAK } from '.';

const keycloakConfig = {
   url: KEYCLOAK.URL,
   realm: KEYCLOAK.REALM,
   clientId: KEYCLOAK.CLIENTID
}

const keycloak = Keycloak(keycloakConfig);
export default keycloak
