import React, { useState, useEffect } from 'react'
import { useSocket } from "../context/socket";
import CIcon from '@coreui/icons-react'
import { FormattedMessage } from 'react-intl'
import { ROLES_ID } from '../config/'

const aboutNav =   {
    _tag: 'CSidebarNavItem',
    name: <FormattedMessage id="global.about" />,
    to: '/about',
    icon: <CIcon name="cil-info" customClasses="c-sidebar-nav-icon" />,
  };

const ChatSideBar = () => {
  const { lastMessages, checkIfChatIsUnread } = useSocket();

  const [isUnread, setIsUnread] = useState<boolean>(false);

  useEffect(() => {
    const chats: number[] = Object.keys(lastMessages ?? {}).map(k => parseInt(k));
    if (chats.length) {
      setIsUnread(chats.some(c => {
        const message = checkIfChatIsUnread(c);
        return message;
      }));
    }
  }, [lastMessages])

  return (
    <>
      <FormattedMessage id="global.sidebar.chat" />
      {
        isUnread ? <a className="chat-circle" /> : <a />
      }
    </>
  )
}

const getNav = (roles: number[]) => {

  const dt_nav = [
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.home" />,
      to: '/',
      icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon" />,
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.gdl" />,
      to: '/giornali-lavoro',
      icon: <CIcon name="cil-description" customClasses="c-sidebar-nav-icon" />,
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.analytics" />,
      to: '/analytics',
      icon: <CIcon name="cil-chart" customClasses="c-sidebar-nav-icon" />,
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.users" />,
      to: '/users',
      icon: <CIcon name="cil-people" customClasses="c-sidebar-nav-icon" />,
    },
    {
      _tag: 'CSidebarNavItem',
      name: <ChatSideBar />,
      to: '/chat',
      icon: <CIcon name="cil-chat-bubble" customClasses="c-sidebar-nav-icon" />,
    },
  ]

  const default_nav = [
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.home" />,
      to: '/',
      icon: <CIcon name="cil-home" customClasses="c-sidebar-nav-icon" />,
    },
    {
      _tag: 'CSidebarNavItem',
      name: <FormattedMessage id="global.sidebar.gdl" />,
      to: '/giornali-lavoro',
      icon: <CIcon name="cil-description" customClasses="c-sidebar-nav-icon" />,
    },
  ]


  if (roles.includes(ROLES_ID.DIRECTOR)) {
    return [...dt_nav, aboutNav];
  }
  else if (roles.includes(ROLES_ID.RUP)) {
    return [...dt_nav.slice(0, dt_nav.length - 2), aboutNav]
  }
  else return [...default_nav, aboutNav];
}


export { getNav }
