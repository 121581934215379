import axios from "axios";
import { COREAPI_ENDPOINTS } from "../../config";
import { IContratti } from "../../types";
import { launchNotification } from ".";

export async function getContratti() {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_CONTRATTI);
}

export async function createContratto(contratto: IContratti) {
  try {
    await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_CONTRATTI, contratto);
    launchNotification("SUCCESSO", "Contratto creato con successo!", "success");
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la creazione del contratto", "danger");
  }
}

export async function updateContratto(contratto: IContratti) {
  try {
    await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_CONTRATTI + "/" + contratto.id, contratto);
    launchNotification("SUCCESSO", "Contratto aggiornato con successo!", "success");
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante l'aggioramento del contratto", "danger");
  }
}
