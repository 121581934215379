import { store } from "react-notifications-component";


export const launchNotification = (title: string, message: string, type: any) => {
  store.addNotification({
    title,
    message,
    type,
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000
    }
  });
}
