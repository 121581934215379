import React, { useEffect, useState } from 'react'
import { useSidebar } from '../context/sidebar'
import { useUser } from "../context/user";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import { getNav } from "./_nav";

// import { useIntl } from 'react-intl'


const TheSidebar = () => {

  const { sidebar, onSidebarChange } = useSidebar()
  const { isAuth, userRoles } = useUser();
  const [menu, setMenu] = useState<any>([])


  useEffect(() => {
    if (isAuth) {
      setMenu(getNav(userRoles));
    }
  }, [isAuth])



  return (
    <CSidebar
      show={sidebar}
      onShowChange={(val: any) => onSidebarChange(val)}
      className='sidebar'
    >
      <CSidebarNav>
        <CCreateElement
          items={menu ?? []}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
