import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react'


interface ILocationProvider {
  location: string[];
  setLocation: Dispatch<SetStateAction<string[]>>
}

const LocationContext = createContext<ILocationProvider | null>(null)

const LocationProvider = ({ children }: {children: React.ReactNode}) => {

    const [location, setLocation] = useState<string[]>([])

    return <LocationContext.Provider
        value={{ location: location, setLocation : setLocation }}>
        {children}
    </LocationContext.Provider>

}

// Helper function to get Context
const useLocation = () => {
    const context = useContext(LocationContext);
    if (!context) {
        throw new Error('useLocation must be used within a LocationProvider');
    }
    return context;
}

export { LocationProvider, useLocation }
