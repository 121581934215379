import { CCard, CCardBody, CCardHeader, CCardFooter } from "@coreui/react";
import React from "react"

interface IProps {
  children?: React.ReactNode,
  title?: string | React.ReactNode
  className?: string
  footer?: React.ReactNode
}

/**
 *
 * @description Use this component for Pages container component
 */
const GCard = ({ children, title, className, footer }: IProps) => {
  return <CCard className={ className }>
    {title !== undefined && <CCardHeader className="gcard-title">{title}</CCardHeader>}
    <CCardBody>
      {children}
    </CCardBody>
    {
      footer &&
      <CCardFooter>
        {footer}
      </CCardFooter>
    }
  </CCard>
}

export default GCard;
