import { COREAPI_ENDPOINTS } from "../../config";
import axios from "axios";

export const getAttachmentUrl = (type: "odl" | "gdl", id: number, fileName: string) => `${COREAPI_ENDPOINTS.URL}${COREAPI_ENDPOINTS.PATH_ATTACHMENTS}/${type}/${id}/${fileName}`;

export const getGdlAttachment = (id: number, fileName: string) => axios.get(getAttachmentUrl("gdl", id, fileName), {
  responseType: "blob"
});

export const getOdlAttachment = (id: number, fileName: string) => axios.get(getAttachmentUrl("odl", id, fileName), {
  responseType: "blob"
});
