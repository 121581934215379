import React from "react";

export const InformativaDatiTitle =
  "Informativa Trattamento Dei Dati Personali";
export const TOSTitle = "Condizioni Generali di Licenza d'uso UCCS";

export const TOS = () => (
  <div>
    <h2>1. Definizioni</h2>
    <p>Con l'utilizzo del termine:</p>
    <p>
      <b>Contratto</b>: accordo sottoscritto tra Licenziante e Licenziatario per
      l’utilizzo del Prodotto di cui le presenti Condizioni Generali ne
      costituiscono parte integrante.
    </p>
    <p>
      <b>Licenziante</b>: proprietario del Software che concede in utilizzo il
      Software secondo i termini e le condizioni stabilite nelle presenti
      Condizioni Generali.
    </p>
    <p>
      <b>Licenziatario</b>: il soggetto che, dopo aver sottoscritto le presenti
      Condizioni di Licenza, utilizza legalmente il Software per proprio conto o
      autorizzandone, sotto propria responsabilità, l’utilizzo a Utenti
      conosciuti.
    </p>
    <p>
      <b>Prodotto</b>: l’<b>UCCS</b> è l’insieme delle componenti software in
      grado di gestire e monitorare in tempo reale, l’esatta posizione dei
      dispositivi in dotazione alle squadre di lavoro e la tipologia di attività
      svolta, consentendo al personale direttivo ed esecutivo di interagire. Il
      Prodotto è frutto dell’integrazione di moduli appositamente sviluppati dal
      Licenziante e diversi componenti open source -che non sono stati
      modificati e di cui si forniscono le licenze d’uso- confezionati in
      un’unica soluzione.
    </p>
    <p>
      <b>Software</b>: L’insieme di script, procedure, strutture dati, algoritmi
      che permettono l’interfacciamento, il corretto funzionamento, il
      monitoraggio dell’operatività tra le componenti SW, la tolleranza ai
      guasti tra le componenti SW attraverso interfacce grafiche, API,
      middleware.
    </p>
    <p>
      <b>Utente/i</b>: il soggetto/i autorizzato/i dal Licenziatario
      all’utilizzo del Software per conto del Licenziatario stesso che
      garantisce per esso/i il rispetto degli obblighi assunti con la Licenza
      d’uso (es. dipendenti, collaboratori).
    </p>
    <p>
      <b>Servizio di assistenza</b>: si intende l’attività svolta dal
      Licenziante per coadiuvare il Licenziatario nell’utilizzo del Software
      secondo la presente Licenza d’uso per tutta la durata dell’accordo.
    </p>
    <h2>2. Diritti di Proprietà Intellettuale</h2>
    <p>
      Il Licenziante riserva a sé interamente ed in maniera esclusiva la
      proprietà intellettuale del Software, di eventuali personalizzazioni. Tali
      contenuti sono protetti da copyright e da leggi o trattati che difendono
      la proprietà intellettuale. Questa Licenza d’uso non garantisce al
      Licenziatario alcun diritto su tali contenuti. Di tale circostanza il
      Licenziatario è edotto e dichiara di accettarla senza riserva alcuna.
      Questa Licenza d’uso consentirà al Licenziatario il solo utilizzo e non
      comporterà in alcun modo cessione della proprietà o di quote del Software
      licenziato. Il Licenziatario si impegna a non consentirne a terzi estranei
      né l’uso occasionale né la riproduzione in qualunque forma, anche per i
      propri amministratori, soci, collaboratori, professionisti, tecnici e
      dipendenti, si obbliga a proteggere i diritti di proprietà esistenti sul
      medesimo. Il Licenziante si riserva ogni diritto non espressamente
      concesso al Licenziatario. I diritti concessi sono limitati ai diritti di
      proprietà intellettuale vantati dal Licenziante sul Software, e non
      includono alcun altro brevetto o diritto di proprietà intellettuale di
      terzi.
    </p>
    <h2>3. Condizioni d’uso del Software</h2>
    <p>
      Al solo fine dell'utilizzo il Licenziante concede al Licenziatario una
      licenza d’uso limitata a n.36 mesi, non trasferibile e non esclusiva per
      l'utilizzo del Software, restando in ogni caso inteso che il Licenziatario
      non può copiare, modificare, realizzare software derivati, sublicenziare,
      vendere, cedere, conferire o trasferire a terzi un qualsiasi diritto sul
      Software se non espressamente autorizzato, né consentire che terzi lo
      facciano. Tutti i diritti non espressamente ceduti al Licenziatario sono
      riservati al Licenziante che detiene la proprietà intellettuale e ogni
      diritto d’autore relativo al Software, alle configurazioni di eventuali
      dispositivi hardware accessori o di protezione, nonché al materiale
      documentale informativo ed esplicativo allegato al Software.
    </p>
    <h2>3.1 Codici Sorgente</h2>
    <p>
      La Licenza d’uso non comporta per il Licenziatario la possibilità di
      ottenere dal Licenziante il Codice Sorgente. Con la Licenza d’uso il
      Licenziante riserva esclusivamente a se’ il diritto di modificare il
      Codice Sorgente in qualsiasi momento. Il Licenziatario assume l’obbligo di
      non visionare e/o modificare il Codice Sorgente nemmeno per il suo proprio
      utilizzo o al fine di migliorare il Software.
    </p>
    <h2>3.2 Divieto di vendita, modifica, copia e cessione </h2>
    <p>
      Il Licenziatario non potrà in alcun modo egli stesso o permettere a terzi
      di manipolare, decompilare, adattare, decodificare, estrarre o copiare, in
      tutto o in parte, nemmeno con tecniche di reverse engineering, il Software
      e/o il relativo Codice Sorgente. Inoltre, il Licenziatario non può vendere
      il Software trasferirlo o renderlo in qualsiasi modo disponibile a terzi,
      a qualunque titolo e ragione, si obbliga inoltre a non riprodurre,
      modificare o tradurre il Software, anche solo parzialmente.
    </p>
    <p>
      Il Licenziatario si impegna affinché qualunque soggetto facente parte
      della propria organizzazione e che sia legittimamente autorizzato ad usare
      il Software dato in licenza, rispetti le disposizioni delle presenti
      Condizioni Generali e, in particolare, i limiti dell’uso della licenza
      stessa.
    </p>
    <p>
      l Licenziatario è consapevole che un comportamento difforme oltre che
      costituire un inadempimento al Contratto, si concretizzerebbe in una
      violazione dei diritti di proprietà intellettuale sul Software e sulla
      relativa documentazione.
    </p>
    <p>
      In caso di utilizzo del Software in maniera non conforme alla previsione
      di cui al presente articolo, il Licenziante avrà il diritto di risolvere
      il Contratto. Qualsiasi comportamento, atto od omissione in violazione dei
      predetti diritti nonché degli obblighi di cui alle presenti Condizioni
      -inclusa la riproduzione non autorizzata dei Programmi e l’acquisizione
      e/o l’alienazione, anche gratuite, non autorizzata di copia degli stessi-
      è legalmente perseguibile. il Licenziatario dovrà corrispondere al
      Licenziante, a titolo di penale e fatto comunque salvo il diritto del
      Licenziante di ottenere il risarcimento del maggior danno, un importo pari
      al corrispettivo concordato.
    </p>
    <h2>4. Assistenza e training on the job</h2>
    <p>
      E’ previsto il servizio di assistenza tecnica da remoto, con un{" "}
      <b>
        limite massimo di 10 ticket annui, per l’intera durata della licenza
      </b>
      . L’assistenza è disponibile 8x5xNBD in orario lavorativo 9:00 – 18:00. il
      Licenziatario deve fornire notizia scritta ragionevolmente dettagliata del
      malfunzionamento del Software attraverso l’inoltro di richiesta al
      seguente indirizzo email: support@gematica.com.
    </p>
    <p>
      Per una durata complessiva di 40 ore è previsto il servizio di formazione,
      che sarà erogato da remoto in modalità videoconferenza.
    </p>
    <h2>5. Modalità di accesso </h2>
    <p>
      Il Licenziatario si impegna, a proprie spese e cura, alla predisposizione,
      acquisto, attivazione e installazione di tutto quanto necessario (a titolo
      esemplificativo ma non esaustivo, idoneo terminale, linee telefoniche,
      apparati telefonici e apparecchiature modem e/o router, programmi di
      accesso ad Internet, abbonamenti, dispositivi, ecc).
    </p>
    <p>
      Il Licenziatario accede al Software utilizzando gli URL e la User e la
      Password ricevuta secondo le modalità indicate dal Licenziante. L’accesso
      remoto e l’utilizzo del Software saranno permessi al Licenziatario a mezzo
      collegamento telematico previa procedura di autenticazione all’indirizzo
      web indicato dal Licenziante.{" "}
    </p>
    <h2>5.1 Implementazioni e aggiornamenti del Software</h2>
    <p>
      Il Licenziante a propria discrezione potrà apportare al Software eventuali
      implementazioni, miglioramenti tecnologici e/o evolutivi, che renderà
      disponibili gratuitamente. Ogni nuova versione è preceduta da una
      comunicazione che elenca le novità in rilascio.
    </p>
    <h2>6. Esclusione di Responsabilità: Malfunzionamenti del Programma</h2>
    <p>
      Il Licenziante non garantisce che le procedure del presente Software
      soddisfino le esigenze del Licenziatario e che queste funzionino in
      qualsiasi combinazione vengano da esso usate, né fornisce alcuna altra
      garanzia, espressa o implicita, relativa al Software; conseguenti rischi
      derivanti dal loro uso sono completamente a carico del Licenziatario e
      quindi in nessun caso il Licenziante sarà responsabile di danneggiamenti
      diretti e/o indiretti conseguenti a difetti di Software. Il Licenziante
      non sarà responsabile in alcun modo dei risultati ottenuti attraverso
      l’uso del Software.
    </p>
    <h2>6.1. Esclusione di responsabilità per danni indiretti.</h2>
    <p>
      In nessun caso il Licenziante sarà responsabile per perdite economiche,
      mancato conseguimento di opportunità e vantaggi commerciali né per
      eventuali danni diretti o indiretti, conseguenti ad un’interruzione totale
      o parziale dell’attività derivanti dall’uso del Software, ritardo,
      malfunzionamento, ivi compresi la perdita dei dati.
    </p>
    <p>
      Il Licenziante non si assume alcuna responsabilità sul contenuto e/o sulla
      correttezza dei Dati e/o degli Archivi eventualmente caricati sul
      Software, sulla loro rispondenza alla legislazione vigente e sull’uso
      fattone dal Licenziatario.
    </p>
    <p>
      In nessun caso il Licenziante sarà responsabile per i danni derivanti da
      malfunzionamenti deI Web e dei dati fruibili tramite Internet.
    </p>
    <h2>6.2. Limitazioni di Responsabilità.</h2>
    <p>
      Il Licenziante garantisce all’Utente l’utilizzo del Servizio CLOUD in
      conformità a quanto definito nella presente licenza e dalle Specifiche di
      Prodotto del Software. Gli obblighi e le responsabilità del Licenziante
      verso il Licenziatario sono quelli definiti dal presente documento. Ad
      ogni modo, in caso di violazione o inadempimento imputabile al
      Licenziante, fatti salvi i limiti inderogabili di legge, il Licenziatario
      conviene che il limite massimo di responsabilità del Licenziante, per
      danni accertati di qualsiasi genere e a qualsiasi titolo conseguenti e
      comunque connessi al cattivo funzionamento del Software in oggetto, sia
      costituito dal rimborso dell’ammontare di € 18.000,00
    </p>
    <p>
      Resta espressamente escluso qualsiasi altro indennizzo e/o risarcimento di
      ogni eventuale maggior danno.
    </p>
    <p>
      Le suindicate limitazioni si applicano per responsabilità sia contrattuali
      che extracontrattuali, ma non si applicano in caso di violazione di
      brevetti o di diritti di autore, danni fisici alla vita o alla salute di
      persone e/o danni a cose o a persone causati con dolo e negligenze grave.
      In nessun caso il Licenziante può essere ritenuto responsabile dei danni
      causati da inadempimenti del Licenziatario e viceversa.
    </p>
    <h2>7. Garanzia sul Software in licenza d’uso.</h2>
    <p>
      Il Software è fornito <i>così com’è</i> nella sua versione di rilascio più
      recente. Fatti salvi i limiti inderogabili di legge, il Licenziante non
      garantisce che le funzioni contenute nel Software soddisfino le esigenze
      del Licenziatario o che le operazioni del Software possano svolgersi senza
      interruzioni o errori o che tutti i difetti contenuti nel Software possano
      essere prontamente corretti. La garanzia sul Software in Licenza d’uso
      consiste nell’invio di un nuovo rilascio del Software atto a eliminare
      anomalie o imperfetti funzionamenti dal Software stesso, purché tali
      anomalie siano riconducibili alle funzioni previste.
    </p>
    <p>
      La presente garanzia ha la durata di 12 mesi ed è limitata come segue: il
      Licenziatario deve fornire notizia scritta ragionevolmente dettagliata del
      malfunzionamento del Software entro il periodo di garanzia al seguente
      indirizzo email: support@gematica.com; le Parti concorderanno
      l’interruzione dell’operatività del Software per il tempo necessario a
      release o correzioni (fix); se il Licenziatario modifica in qualsiasi modo
      il Software, la garanzia si riferirà soltanto al Software non modificato.
      Il Licenziante garantisce esclusivamente che il Software sia in grado di
      eseguire le operazioni previste. Il Licenziante non è responsabile in
      alcun modo (quindi non obbligato ad intervenire) per malfunzionamenti o
      errori dovuti ad uso improprio o atipico del Software, inoltre, non è
      responsabile e non obbligato ad intervenire per malfunzionamenti delle
      Macchine utilizzate dal Licenziatario per l’utilizzo del Software, ivi
      compresi virus, nuove installazioni di programmi, modifiche hardware
      ovvero mal funzionamenti sorti dopo l’intervento di manutenzione di terzi.
      Eventuali interventi del Licenziante non espressamente previsti come
      obbligatori dal presente contratto potranno essere pattuiti dietro ad
      ulteriore compenso. Il Software oggetto di questa licenza d’uso è
      aggregato a software di terze parti, che non sono stati in alcun modo
      modificati dal Licenziante e che sono essi stessi licenziati in conformità
      alle previsioni, per i quali il Licenziante non assume alcun obbligo di
      assistenza tecnica o garanzia di alcun genere.
    </p>
    <h2>8. Corrispettivo</h2>
    <p>
      Il corrispettivo per il servizio regolato dalle presenti condizioni
      contrattuali sonoindicati sull’offerta e/o modulo d’ordine.
    </p>
    <h2>8.1 Pagamenti</h2>
    <p>
      I pagamenti vanno effettuati mediante bonifico bancario entro 30 (trenta)
      dall’emissione della fattura da parte del Licenziante dffm. In caso di
      ritardato o mancato pagamento del canone, il Licenziante avrà il diritto
      di sospendere l'accesso al servizio al Licenziatario e/o di risolvere il
      contratto.
    </p>
    <p>
      Ritardi di pagamento saranno sanabili con l’aggiunta degli interessi di
      mora previsti dalla disciplina in materia di pagamenti tardivi ex D.Lgs
      192/2012 ss.mm.ii.
    </p>
    <h2>9. Durata</h2>
    <p>
      La presente Licenza d’uso ha una validità di n.36 mesi come indicato
      sull’offerta e/o modulo d’ordine: detto periodo è continuo, dalla data di
      inizio a quella di scadenza e non può essere frazionato o sospeso.
    </p>
    <h2>10. Validità e Modifiche delle Condizioni</h2>
    <p>
      Le Condizioni descritte nel presente documento avranno efficacia a partire
      dalla loro accettazione da parte del Licenziatario e resteranno valide per
      l’intera durata.
    </p>
    <h2>11. Risoluzione del Contratto. </h2>
    <p>
      Il Contratto si considererà risolto di diritto nei seguenti casi: a) per
      violazione, da parte del Licenziatario, dei diritti di Proprietà
      intellettuale ex art.2; b) per violazione, da parte del Licenziatario,
      degli obblighi ex art.3.2;
    </p>
    <p>
      c) per ritardi di pagamento del corrispettivo di cui all’art.8.1 superiori
      ai 30 (trenta) giorni; d) per violazione degli obblighi di riservatezza ex
      art.14; e) in tutti i casi in cui il Licenziante ravvisi l’utilizzo a fini
      criminali o contrari all’ordine pubblico od alla legge da parte del
      Licenziatario. Al verificarsi delle circostanze sopra specificate, il
      Licenziante comunicherà via P.E.C., l’intenzione di avvalersi della
      clausola, e l’effetto risolutivo si produrrà in conseguenza della
      ricezione di tale comunicazione. In caso di risoluzione, salvo comunque il
      diritto del Licenziante al risarcimento del danno, al Licenziatario non
      sarà dovuto alcun rimborso. La mancata insistenza da parte del Licenziante
      nel far valere un diritto per la violazione di qualsiasi disposizione
      della Licenza d’uso non potrà essere interpretata quale deroga a tale
      disposizione o rinuncia a tale diritto.
    </p>
    <h2>12. Recesso </h2>
    <p>
      Ciascuna delle due parti ha facoltà di recedere per gravi o giustificati
      motivi in qualsiasi momento dal presente contratto, dandone comunicazione
      per iscritto all’altra a mezzo posta elettronica certificata. Il recesso
      avrà effetto trascorsi 30 giorni dalla ricezione della pec. Il recesso non
      comporterà per il Licenziante la restituzione dei canoni periodici già
      versati dal Licenziatario.
    </p>
    <h2>13. Forza maggiore </h2>
    <p>
      Il Licenziante non sarà responsabile, se non per grave negligenza, per la
      mancata esecuzione del Contratto e/o per l’eventuale ritardo
      nell’adempimento delle proprie obbligazioni di cui alle presenti
      Condizioni Generali e il Licenziatario non avrà titolo di richiedere la
      risoluzione e/o il risarcimento dei danni, qualora ciò derivi da causa di
      Forza Maggiore: i)da cause non attribuibili al Licenziante, ii)dalla
      necessità di conformarsi a leggi, regolamenti, ordini, atti o richieste
      con diritti di precedenza di qualsiasi autorità governativa, civile o
      militare, o organo o organizzazione che ne dipendano; iii)da azioni o
      omissioni del Licenziatario stesso e/o da cause di forza maggiore, quali,
      a puro titolo esemplificativo, pandemia, incendi, inondazioni, sisma,
      intemperie, scioperi o simili a manifestazioni, serrate, chiusure o
      modifiche della fabbrica, embarghi, guerre, tumulti popolari, ritardi o
      deficienze del trasporto, o altre cause similari.
    </p>
    <p>
      Le cause di forza maggiore comporteranno la sospensione dell’Accordo. Se
      le cause di forza maggiore perdurano per oltre 6 (sei) mesi, l’Accordo si
      intende risolto automaticamente e le Parti sono reciprocamente liberate
      dalle rispettive obbligazioni, salvo diverso accordo tra le Parti; si
      applica in ogni caso l’articolo 1463 del Codice Civile.
    </p>
    <h2>14. Riservatezza </h2>
    <p>
      In base al Contratto di Licenza ciascuna Parte s’impegna a trattare come
      confidenziali tutte le informazioni di natura riservata di cui venga a
      conoscenza ad opera dell’altra Parte o che riguardino l’altra Parte. Tali
      informazioni possono essere utilizzate esclusivamente per gli scopi in
      ragione dei quali sono state rivelate.
    </p>
    <p>
      Gli obblighi di riservatezza restano in vigore fino a che le informazioni
      divengano di pubblico dominio
    </p>
    <p>
      Il Licenziatario cura di stabilire il novero delle autorizzazioni per
      l’accesso al Sistema informatico. Il Licenziatario dovrà assicurare,
      assumendo anche l’impegno per il fatto del proprio dipendente, la
      segretezza e la confidenzialità dei dati, delle informazioni, del
      know-how, del software di cui verrà a disporre per dare esecuzione al
      contratto, nonché la segretezza e confidenzialità della di tutte le
      informazioni relative al Software licenziato e ogni altra informazione che
      sia stata rivelata in via confidenziale.
    </p>
    <h2>15. Informativa per il Trattamento dei Dati Personali </h2>
    <p>
      Ai sensi di quanto previsto dal d.lgs 196/03 del 30 giugno 2003 (Codice in
      materia di protezione dei dati personali) nonché dal nuovo Regolamento
      Generale sulla Protezione dei Dati con efficacia dal 25/05/2018. Le Parti
      dichiarano di essere state informate circa l'utilizzo dei dati personali
      che verranno utilizzati nell'ambito dei trattamenti automatizzati o
      cartacei di atti ai fini della esecuzione del presente Accordo. Le Parti
      dichiarano che i dati forniti e qui riportati sono esatti e corrispondono
      al vero esonerandosi reciprocamente da ogni e qualsivoglia responsabilità
      per errori materiali di compilazione ovvero per errori derivanti da una
      inesatta imputazione negli archivi elettronici o cartacei di detti dati.
      Ai sensi della legge indicata, tali trattamenti saranno improntati ai
      principi di correttezza, liceità e trasparenza e nel rispetto delle norme
      di sicurezza.
    </p>
    <h2>16. Legge applicabile e Foro competente </h2>
    <p>Il presente Contratto è regolato dalla legge italiana.</p>
    <p>
      Tutte le eventuali controversie che dovessero insorgere tra le Parti in
      relazione all’interpretazione, esecuzione e risoluzione del presente
      Contratto sarà esclusivamente competente il Foro di Napoli.
    </p>
    <h2>17. Comunicazioni e domiciliazione delle Parti </h2>
    <p>Le parti risulteranno domiciliate presso le rispettive sedi sociali. </p>
    <p>
      Qualsiasi comunicazione tra le parti dovrà avvenire per iscritto o a mezzo
      posta elettronica certificata.
    </p>
  </div>
);

export const InformativaDati = () => (
  <div>
    <p>
      GEMATICA impronta il Trattamento dei Dati ai principi di correttezza,
      liceità e trasparenza nel pieno rispetto del Regolamento UE 2016/679 del
      Parlamento europeo, del Decreto legislativo 30 giugno 2003 n. 196 così
      come integrato e modificato dal Decreto legislativo 10 agosto 2018 n. 101.
    </p>
    <p>
      Il Trattamento dei Dati sarà svolto in forma manuale e/o a mezzo strumenti
      informatici, comunque nel rispetto delle misure di sicurezza di cui
      all’art. 32 del GDPR 2016/679 e all’Allegato B del Codice Privacy (artt.
      33-36 del Codice), ad opera di soggetti appositamente incaricati, in
      ottemperanza a quanto previsto dagli art. 29 GDPR 2016/679. Nel rispetto
      dei principi di liceità, limitazione delle finalità e minimizzazione dei
      Dati, ai sensi dell’art. 5 GDPR 2016/679.
    </p>
    <p>
      <b>
        GEMATICA, in qualità di Responsabile Esterno del Trattamento Esterno dei
        Dati personali del Cliente ETICA Titolare del Trattamento, si impegna:
      </b>
    </p>
    <ul>
      <li>
        a svolgere il Trattamento di Dati personali nel pieno rispetto della
        legislazione vigente nonché della normativa per la protezione dei Dati
        personali (ivi inclusi - oltre al Regolamento UE 2016/679 del Parlamento
        europeo, del Decreto legislativo 30 giugno 2003 n. 196 così come
        integrato e modificato dal Decreto legislativo 10 agosto 2018 n. 101,
        “Codice della privacy e ss.mm.ii. e ulteriori provvedimenti, comunicati
        ufficiali, autorizzazioni Generali, pronunce in genere emessi
        dall'Autorità Garante per la Protezione dei Dati Personali) con
        particolare attenzione all’adozione delle misure di sicurezza di cui
        alla normativa citata;
      </li>
      <li>
        ad eseguire i soli trattamenti funzionali, necessari e pertinenti
        all’esecuzione delle prestazioni contrattuali e, in ogni modo, non
        incompatibili con le finalità per cui i Dati sono stati raccolti;
      </li>
      <li>
        ad adottare le misure di sicurezza di cui al Regolamento UE 2016/679 del
        Parlamento europeo, del Decreto legislativo 30 giugno 2003 n. 196 così
        come integrato e modificato dal Decreto legislativo 10 agosto 2018 n.
        101, ss.mm.ii. e verificare la costante adeguatezza così da ridurre al
        minimo i rischi di perdita e di distruzione, anche accidentale, dei Dati
        stessi, di accesso non autorizzato, di Trattamento non consentito non
        conforme alle finalità della raccolta.
      </li>
    </ul>
    <p>
      <b>Interessati al Trattamento</b> sono coloro i cui Dati personali possono
      essere trattati nell’ambito del Servizio e precisamente:
    </p>
    <ul>
      <li>Dipendenti di ETICA SpA </li>
    </ul>
    <p>
      <b>Tipologie/Categorie</b> di Dati Personali sono:
    </p>
    <ul>
      <li>
        Informazioni di contatto commerciali (nome cognome indirizzo mail
        recapito telefonico)
      </li>
      <li>Identificatori dell’ubicazione (geolocalizzazione)</li>
      <li>Categoria professionale (occupazione, ruolo)</li>
      <li>Accesso ed utilizzo del sistema (User name)</li>
    </ul>
    <p>
      <b>Attività di Trattamento dei Dati Personali</b>
    </p>
    <p>
      La finalità e l'ambito del Trattamento dei Dati consistono nell'erogazione
      delle attività indicate nel contratto{" "}
      <i>ETICA SPA rif.OUT-21-01565-E del 12.08.2021.</i>
    </p>
    <p>
      <b>Natura del Trattamento</b> dei Dati GEMATICA consiste delle seguenti
      attività:
    </p>
    <p>Raccolta dei Dati da device mobili e da piattaforma </p>
    <p>Archiviazione per scopi di memorizzazione e backup in cloud HETZNER </p>
    <p>(https://www.hetzner.com/rechtliches/datenschutz/)</p>
    <p>Eliminazione su richiesta </p>
    <p>
      <b>Durata del Trattamento dei Dati </b>
    </p>
    <p>La durata del rilevamento dei Dati da parte di GEMATICA è:</p>
    <p>
      n.36 mesi come da contratto{" "}
      <i>ETICA SPA rif.OUT-21-01565-E del 12.08.2021</i>
    </p>
    <p>La conservazione dei Dati trattati da parte di GEMATICA è:</p>
    <p>n.24 mesi dal rilevamento del dato</p>
    <p>
      <b>Manutenzione e supporto</b>
    </p>
    <p>
      È possibile che, nel contesto dell'erogazione dei Servizi (ad es. nella
      gestione di una richiesta di supporto), GEMATICA SRL abbia accesso a Dati
      diagnostici, quali ad esempio log di errori e informazioni utente, tali
      Dati diagnostici potrebbero contenere le tipologie di Dati Personali
      citati in precedenza.
    </p>
    <p>
      <b>Categorie particolari di Dati personali</b>
    </p>
    <p>
      In nessun caso, per gli scopi legati all’attività da svolgere, GEMATICA
      tratterà Dati relativi a “categorie particolari” (dati che rivelino{" "}
      <i>
        “l’origine razziale o etnica, le opinioni politiche, le convinzioni
        religiose o filosofiche, o l’appartenenza sindacale…dati genetici, Dati
        biometrici intesi a identificare in modo univoco una persona fisica,
        Dati relativi alla salute o alla vita sessuale o all’orientamento
        sessuale della persona”
      </i>
      ).
    </p>
    <p>
      GEMATICA SRL non sarà mai responsabile di violazione dei Dati personali
      dovuta a incuria, negligente gestione e/o utilizzo dei SW forniti. Il
      Cliente ha l’obbligo di notificare eventuali violazioni di Dati senza
      ingiustificato ritardo entro 72 ore, dal momento in cui si è venuto a
      conoscenza della violazione, a meno che sia improbabile che la violazione
      dei Dati personali presenti un rischio per i diritti e le libertà delle
      persone fisiche.
    </p>
    <p>
      <b>Diritti dell’interessato</b>
    </p>
    <p>
      In ogni momento, il Cliente potrà esercitare, ai sensi dell’art. 7 del
      Codice Privacy e degli artt. 15-22 del GDPR 2016/679, il diritto di:
    </p>
    <ul>
      <li>
        chiedere la conferma dell’esistenza o meno di propri Dati personali;
      </li>
      <li>
        ottenere le indicazioni circa le finalità del Trattamento, le categorie
        dei Dati personali, i destinatari o le categorie di destinatari cui i
        Dati personali sono stati o saranno comunicati e il periodo di
        conservazione;
      </li>
      <li>ottenere la rettifica e la cancellazione dei Dati;</li>
      <li>ottenere la limitazione del Trattamento;</li>
      <li>
        ottenere la portabilità dei Dati, ossia riceverli in un formato
        strutturato, di uso comune e leggibile da dispositivo automatico, e
        trasmetterli ad un altro titolare del Trattamento senza impedimenti;
      </li>
      <li>
        opporsi al Trattamento in qualsiasi momento ed anche nel caso di
        Trattamento per finalità di marketing diretto;
      </li>
      <li>
        opporsi ad un processo decisionale automatizzato relativo alle persone
        fisiche, compresa la profilazione.
      </li>
    </ul>
    <p>
      Il Cliente può esercitare i Suoi diritti con richiesta scritta inviata al
      Gematica, all’indirizzo della sede o all’indirizzo di posta elettronica
      certificata gematica@pec.it
    </p>
    <p>
      Il Responsabile Esterno del Trattamento è Gematica, con Sede Operativa in
      Napoli, Via Diocleziano 107, recapito telefonico 081.0108700, email pec:
      gematica@pec.it, nella figura del legale rappresentante.
    </p>
  </div>
);
