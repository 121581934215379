import React from 'react'
import { useIntl } from "react-intl";
import { useLocation } from "../context/location";
import {
  CHeader,
  CHeaderNav,
  CSubheader,
  /* CDropdown,
  CDropdownItem,
  CDropdownToggle,
  CDropdownMenu, */
  CBreadcrumb,
  CBreadcrumbItem,
} from '@coreui/react'

import logoGematica from '../assets/img/brand/logo.jpg';

//import context
//import { useIntlContext } from '../context/intl'
//import { useIntl } from 'react-intl'


//import constants
// import { LOCALES } from '../config/'

//import utils
//import { localeFlag } from '../components/helpers/'
import { UIHeaderDropdown } from './'

const UIHeader = () => {

  // const { locale, setLocale } = useIntlContext()
  const { formatMessage } = useIntl()
  const { location } = useLocation();

  return (
    <CHeader withSubheader className="bg-color-primary-dark header-main" >
      <CHeaderNav className="d-flex px-3 justify-content-start flex-grow-1">
        <div className="c-subheader-nav">
          <img className="logo" src={logoGematica} alt="Logo azienda" />
        </div>
      </CHeaderNav>
      <CHeaderNav className="d-flex px-3 justify-content-end flex-grow-1">
        <div className="c-subheader-nav">
          <UIHeaderDropdown />
        </div>
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">
          <CBreadcrumbItem><a href="/#">Home</a></CBreadcrumbItem>
          {
            location?.map(l => <CBreadcrumbItem key={l}><a className="c-breadcrumb">{formatMessage({ id: l })}</a></CBreadcrumbItem>)
          }
        </CBreadcrumb>
      </CSubheader>
    </CHeader>
  )
}

export default UIHeader
