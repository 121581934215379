import React from 'react'

interface ILoadingProps {
    text?: string
    containerClass?: string
    textClass?: string
    spinnerClass?: string
}

const Loading = ({text, containerClass, textClass = "fade-in-fade-out text-center", spinnerClass = "spinner-custom" }: ILoadingProps) => {
    return (
        <div className={containerClass}>
            <div className={spinnerClass} />
            <div className={textClass}>{text}</div>
        </div>
    )
}

export default Loading
