import React from 'react';

//import roles
import { ROLES_ID } from './config/'

const Home = React.lazy(() => import('./pages/Home'));
const GiornaliLavoro = React.lazy(() => import('./pages/GiornaliLavoro'));
const OrdineLavoroDettaglio = React.lazy(() => import('./pages/OrdineLavoroDettaglio'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const Chat = React.lazy(() => import('./pages/Chat'));
const Users = React.lazy(() => import('./pages/Users'));
const About = React.lazy(() => import('./pages/About'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home, role: [ROLES_ID.DIRECTOR, ROLES_ID.RUP, ROLES_ID.DO, ROLES_ID.DL, ROLES_ID.ISPECTOR] },
  { path: '/giornali-lavoro', exact: true, name: 'Giornali dei Lavori', component: GiornaliLavoro, role:[ROLES_ID.DIRECTOR, ROLES_ID.RUP, ROLES_ID.DO, ROLES_ID.DL, ROLES_ID.ISPECTOR] },
  { path: '/ordini-lavoro/:id/dettaglio', exact: true, name: "Ordini dei Lavori", component: OrdineLavoroDettaglio, role: [ROLES_ID.DIRECTOR, ROLES_ID.RUP, ROLES_ID.DO, ROLES_ID.DL, ROLES_ID.ISPECTOR] },
  { path: '/analytics', exact: true, name: "Analytics", component: Analytics, role: [ROLES_ID.DIRECTOR, ROLES_ID.RUP] },
  { path: '/chat', exact: true, name: "Chat", component: Chat, role: [ROLES_ID.DIRECTOR] },
  { path: '/users', exact: true, name: "Users", component: Users, role: [ROLES_ID.DIRECTOR] },
  { path: '/about', exact: true, name: "About", component: About, role: [ROLES_ID.DIRECTOR, ROLES_ID.RUP, ROLES_ID.DO, ROLES_ID.DL, ROLES_ID.ISPECTOR] }
];

export default routes;
