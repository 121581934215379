import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useKeycloak } from '@react-keycloak/web'
import { useUser } from "../context/user";

const UIHeaderDropdown = () => {
  const [keycloak] = useKeycloak()

  const { user } = useUser();

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mr-2 ml-4 header"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={'avatars/6.jpg'}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
        {
          user &&
          <>
          <div className="c-username">{user.fname + " " + user.lname}</div>
          <CIcon className="c-caret" name="cil-caret-bottom" />
          </>
        }
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 pb-0 profile-menu" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          className="text-center"
          color= 'light'
        >
          <h6>ACCOUNT</h6>
        </CDropdownItem>
        <CDropdownItem className="pl-2 pt-2 pb-2" onClick={()=> keycloak.logout()}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
         <span>Logout</span>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default UIHeaderDropdown
