import React, { useState, useEffect, useContext, createContext } from "react";
import { useKeycloak } from '@react-keycloak/web';
import { WEBSOCKET_URL, ROLES_ID } from "../../config";
import { IPosition, IMessage, IUser } from "../../types";
import { getUsersByRole, getMessages, markMessageAsRead } from "../../components/helpers";
import { useUser } from "../user";

interface ISocketProvider {
  allowedServices: string[] | null;
  openPositionSocket: () => void;
  positions: IPosition[] | null;
  openChatSocket: () => void;
  lastMessages: {[key: string]: any};
  lastMessage: IMessage | null;
  updateMessages: (messages: IMessage[], userid: number, isNew?: boolean) => void;
  markChatRead: (userid: number) => void;
  confirmMessage: (message: IMessage, userid: number) => void;
  removeMessage: (message: IMessage, userid: number) => void;
  checkIfChatIsUnread: (userid: number) => IMessage | null;
  [key: string]: any;
}

const SocketContext = createContext<ISocketProvider | null>(null);

/**
 * @description Promise wrapper for websocket creation
 * @params {string} service
 * @returns {Promise<Websocket>}
 */
const socketConnWithPromise = (onError: () => void): Promise<WebSocket> => {
    return new Promise<WebSocket>( (resolve, reject) => {
    var socket: WebSocket = new WebSocket(WEBSOCKET_URL);
    socket.onopen = () => {
      return resolve(socket);
    };
    socket.onerror = (err) => {
      onError();
      return reject(err);
    };
    socket.onclose = (err) => {
      onError();
      return reject(err);
    }
  })
}

const authenticateSocket = (socket: WebSocket, token: string): Promise<any> => {
  return new Promise<any>( (resolve, reject) => {
  socket.onmessage = ({data}) => {
    const { payload } = JSON.parse(data);
    if (Array.isArray(payload) === true) resolve(payload);
    else reject(payload);
  }
  socket.send(JSON.stringify({ type: "auth", payload: { token } }));
})
}

const messageHandler: {[key: string]: (data: any) => void} = {};
const getMessageHandler = () => messageHandler;

const fetchMessages = (userid: number): Promise<{ id: number, messages: IMessage[] }> => (
  new Promise<{ id: number, messages: IMessage[] }>((resolve) => {
    getMessages(userid).then(({data: messages}) => resolve({ id: userid, messages }))
  })
)

const SocketProvider = ({children}: { children: React.ReactNode }) => {

  const [keycloak, initialized] = useKeycloak();
  const { userRoles } = useUser();

  const [allowedServices, setAllowedServices] = useState<string[] | null>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const [positions, setPositions] = useState<IPosition[] | null>(null);
  const [lastMessages, setLastMessages] = useState<{[key: string]: IMessage[]}>({});
  const [lastMessage, setLastMessage] = useState<IMessage | null>(null);
  const [areMessageLoaded, setAreMessageLoaded] = useState<boolean>(false);

  const [socketError, setSocketError] = useState<boolean>(false);
  const [isSocketReady, setIsSocketReady] = useState<boolean>(false);

  const initializeSocket = async () => {
    const newSocket = await socketConnWithPromise(() => setSocketError(true));
    setSocket(newSocket);
    const authServices = await authenticateSocket(newSocket, keycloak.token!);
    setAllowedServices(authServices);
    newSocket.onmessage = ({data}) => {
      const { type, payload } = JSON.parse(data);
      const handler = getMessageHandler();
      handler[type] && handler[type](payload);
    }
    return newSocket;
  }

  const getSocket = async (): Promise<WebSocket | null> => {
    if (initialized === false || keycloak.authenticated === false ) return null;
    return socket ?? (await initializeSocket());
  }

  const openPositionSocket = async () => {
    const currentSocket = await getSocket();
    if (currentSocket) {
      messageHandler['live_positions'] = (payload) => setPositions(Object.values(payload));
      currentSocket.send(JSON.stringify({ type: "live_positions", payload: {}}));
    }
  }

  const openChatSocket = async () => {
    const currentSocket = await getSocket();
    if (currentSocket) {
      messageHandler['chat'] = (payload: IMessage) => {
        setLastMessage(payload);
      }
      currentSocket.send(JSON.stringify({ type: "chat", payload: {}}));
    }
  }

  const updateMessages = (messages: IMessage[], userid: number, isNew?: boolean) => {
    if (lastMessages[userid]) {
      const updatedMessages: IMessage[] = isNew ? [...messages, ...lastMessages[userid]] : [...(lastMessages[userid]), ...messages];
      setLastMessages({...lastMessages, [userid]: updatedMessages});
    }
  }

  const markChatRead = (userid: number) => {
    const unreadMessage: IMessage | null = checkIfChatIsUnread(userid);
    if (unreadMessage) {
      unreadMessage.read_at = new Date();
      markMessageAsRead(unreadMessage.id);
      setLastMessages({...lastMessages});
    }
  }

  const checkIfChatIsUnread = (userid: number): IMessage | null => {
    const chatData = lastMessages[userid];
    const lastReceivedMessage: IMessage | null = chatData?.find(m => m.senderid === userid) ?? null;
    return (lastReceivedMessage && !lastReceivedMessage.read_at) ? lastReceivedMessage : null;
  }

  const loadMessages = async () => {
    try {
      const {data: users} = await getUsersByRole(ROLES_ID.MANTAINER);
      const mantainers: IUser[] = users.filter(({deleted_at}: IUser) => !deleted_at);
      const messageData: {[userid: string]: IMessage[]} = {};
      const promiseArr: Promise<{id: number, messages: IMessage[]}>[] = [];
      for (const { id } of mantainers) {
        promiseArr.push(fetchMessages(id!));
      }
      const resolvedData: {id: number, messages: IMessage[]}[] = await Promise.all(promiseArr);
      for (const { id, messages } of resolvedData) {
        messageData[id] = messages;
      }
      setLastMessages(messageData);
      setAreMessageLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }

  const confirmMessage = (message: IMessage, userid: number) => {
    const index: number = lastMessages[userid].findIndex(m => m.id === 0 && message.message === m.message);
    if (index >= 0) {
      lastMessages[userid][index] = message;
      setLastMessages({...lastMessages});
    }
    else {
      updateMessages([message], userid, true);
    }
  }

  const removeMessage = (message: IMessage, userid: number) => {
    const index: number = lastMessages[userid].findIndex(m => m.id === 0 && message.message === m.message);
    if (index >= 0) {
      lastMessages[userid].splice(index, 1);
      setLastMessages({...lastMessages});
    }
  }

  const initSockets = async () => {
    try {
      setSocketError(false);
      const socket = await initializeSocket();
      messageHandler['chat'] = (payload: IMessage) => {
        setLastMessage(payload);
      }
      messageHandler['live_positions'] = (payload) => setPositions(Object.values(payload));
      if (areMessageLoaded === false) {
        await loadMessages();
      }
      socket.send(JSON.stringify({ type: "chat", payload: {}}));
      socket.send(JSON.stringify({ type: "live_positions", payload: {}}));
      setIsSocketReady(true);
    } catch (err) {
      console.log(err);
      setSocketError(true);
    }
  }

  useEffect(() => {
    if (Object.keys(lastMessages).length === 0 && userRoles?.length) {
      if (userRoles.includes(ROLES_ID.DIRECTOR)) initSockets();
      else setIsSocketReady(true);
    }
  }, [lastMessages, userRoles])

  useEffect(() => {
    if (lastMessage !== null && Object.keys(lastMessages).length) {
      lastMessage.isFromSocket = true;
      lastMessage.isUnread = true;
      const updatedMessages = [lastMessage, ...(lastMessages[lastMessage.senderid] ?? [])]
      setLastMessages({ ...lastMessages, [lastMessage.senderid]: updatedMessages});
    }
  }, [lastMessage])

  useEffect(() => {
    if (socketError) {
      initSockets();
    }
  }, [socketError])


  return (
    <SocketContext.Provider
      value={{
        allowedServices,
        openPositionSocket,
        positions,
        openChatSocket,
        lastMessages,
        lastMessage,
        updateMessages,
        markChatRead,
        confirmMessage,
        removeMessage,
        checkIfChatIsUnread,
        isSocketReady
       }}>
      {children}
    </SocketContext.Provider>
  )

}

const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === null) {
    throw new Error("useSocket must be used within SocketProvider");
  }
  return context;
}

export { useSocket, SocketProvider };
