import axios from "axios";
import { COREAPI_ENDPOINTS } from "../../config";
import { IOrdineLavori } from "../../types";
import { launchNotification } from ".";

export async function getOrdiniLavori() {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL);
}

export async function getOrdineLavoroById(id: number) {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL + "/" + id);
}

export async function createOrdineLavoro(odl: IOrdineLavori): Promise<any> {
  try {
    const response = await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL, odl);
    launchNotification("SUCCESSO", "Ordine dei Lavori creato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la creazione dell'ordine dei lavori", "danger");
    throw new Error("");
  }
}

export async function updateOrdineLavoro(odl: IOrdineLavori): Promise<any> {
  try {
    const response = await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL + "/" + odl.id, odl);
    launchNotification("SUCCESSO", "Ordine dei Lavori modificato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la modifica dell'ordine dei lavori", "danger");
    throw new Error("");

  }
}

export async function closeOrdineLavoro(odl: IOrdineLavori): Promise<any> {
  try {
    const response = await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL + "/" + odl.id, { state: "Chiuso"});
    launchNotification("SUCCESSO", "Ordine dei Lavori chiuso con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante la chiusura dell'ordine dei lavori", "danger");
    
  }
}

export async function completeOrdineLavoro(odl: IOrdineLavori): Promise<any> {
  try {
    const response = await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ODL + "/" + odl.id, { state: "Completato"});
    launchNotification("SUCCESSO", "Ordine dei Lavori completato con successo!", "success");
    return response;
  } catch (err) {
    launchNotification("ERRORE", "Si è verificato un errore durante il completamento dell'ordine dei lavori", "danger");
  }
}
