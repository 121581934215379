import * as CONFIG_DEV from "./config.dev";
import * as CONFIG_PROD from "./config.prod";

const config: any = process.env.REACT_APP_ENVIRONMENT === "development" ? { ...CONFIG_DEV } : { ...CONFIG_PROD };

export const ROLES = config.ROLES;
export const ROLES_ID = config.ROLES_ID;
export const LOCALES = config.LOCALES;
export const KEYCLOAK = config.KEYCLOAK;
export const COREAPI_ENDPOINTS = config.COREAPI_ENDPOINTS;

export const DATETIME_FORMAT = config.DATETIME_FORMAT;
export const DATE_FORMAT = config.DATE_FORMAT;

export const WEBSOCKET_URL = config.WEBSOCKET_URL;

export const DEFAULT_CONTRACT_ID = config.DEFAULT_CONTRACT_ID;

export { default as messages } from "./messages";
export { default as GDL_STATES, GDL_STATES_REVERSE_KEYS } from "./gdlStates";
