import axios from "axios";
import { COREAPI_ENDPOINTS } from "../../config";

export async function getMessages(userid: number, messageid?: number)  {
  return axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_MESSAGES + "/chatHistory/" + userid, {
    params: { last_message_id: messageid },
    timeout: 60 * 1000
  })
}

export async function sendMessage(text: string, recipientid: number) {
  try {
    return await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_MESSAGES + "/sendmessage", { text, recipientid });
  } catch (err) {
    throw new Error(err);
  }
}

export async function markMessageAsRead(messageid: number) {
  try {
    return await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_MESSAGES + "/mark-as-read/" + messageid, {});
  } catch (err) {
    throw new Error(err);
  }
}
