import React from "react"
import { CButton } from "@coreui/react";
import { FormattedMessage } from "react-intl";
import "animate.css";
import "react-notifications-component/dist/theme.css";

export interface GButtonProps extends CButton {
  messageKey?: string,
  icon?: string, //path to img
  jsxIcon?: JSX.Element //using a JSX.Element as icon (for ex. Fontawesome)
  disabled?: boolean
}

export default (props: GButtonProps) => {
  return <CButton
    variant={props.variant}
    color={props.color}
    className={"g-button ml-0 mt-2 mb-2" + props.className}
    onClick={props.onClick}
    size={props.size}
    type={props.type}
    disabled={props.disabled ?? false}
  >
    <div className='d-flex justify-content-center align-items-center button-icon'>
      {props.icon !== undefined && <img src={props.icon} alt=""/>}
      {props.jsxIcon !== undefined && props.jsxIcon}
      <FormattedMessage id={props.messageKey} />
    </div>
  </CButton>
}
