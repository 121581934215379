const messages: { [lang: string]: { [key: string]: string } } = {
  'it-IT': {
    "global.language.en-GB": "Inglese",
    "global.language.it-IT": "Italiano",
    "global.api-loading": "Caricamento dei dati in corso...",
    "global.api-error": "Errore durante il caricamento dei dati.",
    "global.confirm": "Conferma",
    "global.confirm-and-save": "Salva e Conferma",
    "global.undo": "Annulla",
    "global.add": "Aggiungi",
    "global.delete": "Elimina",
    "global.select-all": "Seleziona Tutti",
    "global.deselect-all": "Deseleziona Tutti",
    "global.edit": "Modifica",
    "global.save": "Salva",
    "global.detail": "Dettaglio",
    "global.close": "Chiudi",
    "global.actions": "Azioni",
    "global.oggetto": "Oggetto",
    "global.codice": "Codice",
    "global.about": "Info su UCCS",
    "global.ubicazione": "Ubicazione",
    "global.data_creazione": "Creato il",
    "global.lavorato_il": "Data Lavori",
    "global.start_date": "Data Inizio",
    "global.filter-reset": "Reimposta Filtri",
    "global.from": "Da:",
    "global.to": "A:",
    "global.end_date": "Data Fine",
    "global.contratto": "Contratto",
    "global.refresh": "Aggiorna",
    "global.users": "Utenti",
    "global.description": "Descrizione",
    "global.type": "Tipo",
    "global.lname": "Cognome",
    "global.name": "Nome",
    "global.odl": "Ordine di Lavoro",
    "global.state": "Stato",
    "global.poi": "Impianto",
    "global.attachment": "Allegati",
    "global.no-attachment": "Nessun allegato presente",
    "global.sidebar.home": "Home",
    "global.sidebar.contracts": "Contratti",
    "global.sidebar.odl": "Ordini di Lavoro",
    "global.sidebar.users": "Utenti",
    "global.sidebar.analytics": "Analisi",
    "global.sidebar.gdl": "Giornali dei Lavori",
    "global.sidebar.chat": "Chat",
    "table.itemsperpage": "Elementi per pagina",
    "global.attachment.limit": "Numero massimo di Allegati: ",
    "global.mantainer": "Capo Manutentore",
    "global.days": "Giorni",
    "global.day": "Giorno",
    "global.hours": "Ore",
    "global.minutes": "Minuti",
    "global.polo": "Polo",
    "global.do": "Direttore Operativo",
    "global.vdo": "Ispettore di Cantiere",
    // GDL PAGE START
    "gdl.title": "Giornali dei Lavori",
    "gdl.modal.add": "Crea Giornale dei Lavori",
    "gdl.assigned_to": "Assegnato a",
    "gdl.modal.delete": "Elimina Giornale dei Lavori",
    "gdl.modal.details": "Dettagli Giornale dei Lavori",
    "gdl.modal.edit": "Modifica Giornale dei Lavori",
    "gdl.modal.finalize": "Modifica Giornale dei Lavori",
    "gdl.modal.schedule": "Pianifica Attività",
    "gdl.ubicazione": "Ubicazione",
    "gdl.meteo": "Condizioni meteo",
    "gdl.progressivo": "Progressivo",
    "gdl.note": "Note",
    "gdl.tipo_lavoro": "Tipologia Lavoro",
    "gdl.attivita_eseguite": "Attività",
    "gdl.mezzi": "Mezzi",
    "gdl.materiali": "Materiali Utilizzati",
    "gdl.rilievo": "Rilievo",
    "users.name": "Nome",
    "users.specialization": "Specializzazione",
    "gdl.materiali.unity": "Unità",
    "gdl.materiali.quantity": "Quantità",
    "gdl.materiali.fornitura_impresa": "Fornitura Impresa",
    "gdl.mezzi.plate": "Targa",
    "gdl.mezzi.noleggio": "Noleggio",
    "gdl.mezzi.noleggiato_a_caldo": "A caldo",
    "gdl.mezzi.noleggiato_a_freddo": "A freddo",
    "gdl.mezzi.driver": "Guidatore",
    "gdl.materiali.unit": "Unità",
    "gdl.materiali.fornitura_committente": "Fornitura Committente",
    "gdl.riferimento": "Riferimento",
    "gdl.verifica_pericoli": "Verifica rispondenza pericoli/criticità",
    "gdl.segnaletica": "Segnaletica cantiere",
    "gdl.tesserini": "Tesserini riconoscimento",
    "gdl.allestimento": "Allestimento cantiere",
    "gdl.dpi": "Utilizzo D.P.I.",
    "gdl.documenti": "Documenti",
    "gdl.stoccaggio": "Stoccaggio materiali",
    "gdl.stoccaggio_smaltimento": "Stoccaggio e smaltimento rifiuti",
    "gdl.autorizzazione": "Autorizzazione trasporto rifiuti",
    "gdl.rispetto_prescrizioni": "Rispetto prescrizioni deroga",
    "gdl.rif_elab_progetto": "Rif. Elab. progetto",
    "gdl.scavo": "Scavo",
    "gdl.montaggio": "Montaggio tubazioni",
    "gdl.controllo_giunti": "Controllo giunti",
    "gdl.rinterro_scavo": "Rinterro con materiale di scavo",
    "gdl.rinterro_cava": "Rinterro con materiale di cava",
    "gdl.posa": "Posa nastro segnalatore",
    "gdl.ripristino": "Ripristino stradale",
    "gdl.controllo": "Controllo campionamento c.l.s. e acciaio",
    "gdl.prova_cantiere": "Prova di cantiere",
    "gdl.controllo_visivo": "Controllo visivo",
    "gdl.controllo_documentale": "Controllo documentale",
    "gdl.comunicazione_cse": "Comunicazione al C.S.E.",
    "gdl.data_ora_fine": "Data e Ora fine",
    "gdl.assistente_direzione": "Assistente alla Direzione dei Lavori",
    "gdl.visto_direttore": "Visto il Direttore dei Lavori",
    'gdl.addetti_giornale_lavori': "Addetti",
    "gdl.materiali_utilizzati": "Materiali Utilizzati",
    "gdl.sign.title": "Conferma",
    "gdl.revoke.title": "Revoca",
    "gdl.sign.prompt": "Sei sicuro di voler firmare questo documento?",
    "gdl.revoke.prompt": "Sei sicuro di voler revocare questo documento?",
    "gdl.attachment.validation.image-type": "Il file deve avere una delle seguenti estensioni: .png, .jpg, .jpeg.\n",
    "gdl.attachment.validation.image-name": "Esista già un file con questo nome per questo Giornale dei Lavori.",
    "gdl.modal.multi-confirm": "Firma Giornali dei Lavori",
    "gdl.modal.multi-revoke": "Revoca Giornali dei Lavori",
    "gdl.modal.multi-confirm.prompt": "Stai per firmare i seguenti Giornali dei Lavori:",
    "gdl.modal.multi-revoke.prompt": "Stai per revocare i seguenti Giornali dei Lavori:",
    // GDL PAGE END
    "btn.removeAttach": "Rimuovi allegati",
    "btn.removeRilievo": "Rimuovi rilievo",
    // ODL PAGE START
    "odl.detail.title": "Dettaglio Ordine di Lavoro",
    // USER PAGE START
    "user.phone": "Cellulare",
    "user.phone2": "Cellulare 2",
    "user.password-reset": "Reimposta Password",
    "user.modal.title": "Utente",
    "addetto.modal.title": "Addetto",
    "addetto.modal.delete.prompt": "Sei sicuro di voler eliminare l'addetto ",
    // USER PAGE END
    // HOME PAGE START
    "home.map.title": "Mappa",
    "odl.card.title": "Ordini di Lavoro",
    "odl.modal.add.title": "Crea Ordine di Lavoro",
    "odl.modal.edit.title": "Modifica Ordine di Lavoro",
    "home.map.mantainer-list.last-updated": "Aggiornato Al",
    "home.map.mantainer-list.name": "Nome",
    "home.map.mantainer-list.title": "Squadre Manutenzione",
    // LEGENDA GDL START
    'gdl.state.description.redact': "Giornale dei Lavori creato dal Direttore Tecnico, nessun Capo Manutentore assegnato",
    'gdl.state.description.working': 'Giornale dei Lavori al quale è stato assegnato un Capo Manutentore ed in attesa di conferma da parte di esso',
    'gdl.state.description.approval': 'Giornale dei Lavori in attesa di conferma dal Direttore Tecnico',
    'gdl.state.description.verification': 'Giornale dei Lavori in attesa di conferma dal Direttore Operativo',
    'gdl.state.description.approval_gdl': 'Giornale dei Lavori in attesa di conferma dal Direttore Lavori',
    'gdl.state.description.closure': 'Giornale dei Lavori in attesa di conferma dal RUP',
    'gdl.state.description.closed': 'Giornale dei Lavori approvato dal RUP, quando si trova in questo stato non potrà essere più modificato.',
    // LEGENDA GDL END
    // ANALYTICS START
    'analytics.general': 'Informazioni Generali',
    'analytics.general.number': 'Totale Giornali dei Lavori',
    'analytics.general.avg-time': 'Tempo Medio Chiusura Giornali dei Lavori',
    'analytics.chart.state': 'Stato Giornali dei Lavori',
    'analytics.chart.user': 'Giornali dei Lavori per Manutentore',
    'analytics.chart.gdl-per-odl': 'Giornali dei Lavori per Ordine di Lavoro',
    'analytics.chart.gdl-per-poi': 'Giornali dei Lavori per Impianto',
    'analytics.chart.type-per-odl': 'Tipologia Lavori per Ordine di Lavoro',
    'analytics.chart.type-per-poi': 'Tipologia Lavori per Impianto',
    'analytics.timeframe': 'Periodo di Riferimento'
  },
  'en-GB': {
    "global.language.en-GB": "English",
    "global.language.it-IT": "Italian",
    "global.add": "Add",
    "global.actions": "Actions",
    "global.close": "Close",
    "global.oggetto": "Subject",
    "global.codice": "Code",
    "global.refresh": "Refresh",
    "global.users": "Users",
    "global.description": "Description",
    "global.type": "Type",
    "global.ubicazione": "Location",
    "global.controllo": "Control",
    "global.data_ora": "Date and Time",
    "table.itemsperpage": "Items per page",
    // GDL PAGE START
    "gdl.title": "Giornale dei Lavori",
    "gdl.modal.add": "Add Giornale dei Lavori",
    "gdl.modal.details": "Giornale dei Lavori details",
    "gdl.ubicazione": "Location",
    "gdl.meteo": "Weather conditions",
    "gdl.tipo_lavoro": "Job type",
    "gdl.attivita": "Activities",
    "gdl.mezzi": "Vehicles",
    "gdl.mezzi.targa": "Plate",
    "gdl.mezzi.noleggio": "Rental",
    "gdl.mezzi.noleggiato_a_caldo": "With operator",
    "gdl.mezzi.noleggiato_a_freddo": "Operator-free",
    "gdl.mezzi.driver": "Driver",
    "users.name": "Name",
    "users.specialization": "Specialization",
    "gdl.materiali": "Used materials",
    "gdl.rilievo": "Rilievo",
    "gdl.materiali.unit": "Unit",
    "gdl.materiali.quantity": "Quantity",
    "gdl.materiali.fornitura_impresa": "Supply Company",
    "gdl.materiali.fornitura_committente": "Customer Supply",
    "gdl.riferimento": "Reference",
    "gdl.verifica_pericoli": "Compliance check with hazards/critical issues",
    "gdl.segnaletica": "Worksite signage",
    "gdl.tesserini": "Identification cards",
    "gdl.allestimento": "Worksite preparation",
    "gdl.dpi": "P.P.E. adoption",
    "gdl.documenti": "Documents",
    "gdl.stoccaggio": "Materials storage",
    "gdl.stoccaggio_smaltimento": "Waste storage and disposal",
    "gdl.autorizzazione": "Waste transport authorization",
    "gdl.rispetto_prescrizioni": "Compliance with derogation provisions",
    "gdl.rif_elab_progetto": "Elab. project ref.",
    "gdl.scavo": "Excavation",
    "gdl.montaggio": "Piping assembly",
    "gdl.controllo_giunti": "Joints check",
    "gdl.rinterro_scavo": "Excavation material backfill",
    "gdl.rinterro_cava": "Quarry material backfill",
    "gdl.posa": "Signaling tape placing",
    "gdl.ripristino": "Road restoring",
    "gdl.controllo": "Concrete and steel sampling check",
    "gdl.prova_cantiere": "Worksite test",
    "gdl.controllo_visivo": "Visual check",
    "gdl.controllo_documentale": "Documentary check",
    "gdl.comunicazione_cse": "Communications to C.S.E.",
    "gdl.data_ora_fine": "Date and Time end",
    "gdl.assistente_direzione": "Assistente alla Direzione dei Lavori",
    "gdl.visto_direttore": "Visto il Direttore dei Lavori",
    // GDL PAGE END
    "btn.removeAttach": "Rimuovi allegati",
    "btn.removeRilievo": "Rimuovi rilievo"
  }
}

export default messages;
