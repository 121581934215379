import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react'

interface ISidebarProvider {
  sidebar: boolean;
  onSidebarChange: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = createContext<ISidebarProvider | null>(null);

interface ISidebarProviderProps {
  children: React.ReactNode;
}

const SidebarProvider = ({ children }: ISidebarProviderProps) => {

    const [sidebar, onSidebarChange] = useState(true)
    return <SidebarContext.Provider
        value={{ sidebar: sidebar, onSidebarChange : onSidebarChange }}>
        {children}
    </SidebarContext.Provider>

}

// Helper function to get Context
const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
}

export { SidebarProvider, useSidebar }
