import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loading from "../components/Loading";
import axios from 'axios';
import { useUser } from "../context/user";
import { useSocket } from "../context/socket";
import Consent from "../pages/consent/Consent";

const LoadingComponent = () => (
  <Loading
      text={"Unified Command Control System"}
      containerClass='loading-app'
      spinnerClass='spinner-custom initial-spinner'
      textClass='fade-in-fade-out '
    />
)

// Containers
const TheLayout = React.lazy(() => import('../containers/UILayout'));

export const AppRouter = () => {

    const [keycloak, initialized] = useKeycloak();

    const userState = useUser();

    const [isLoading, setIsLoading] = useState(false);
    const [isAuth, setIsAuth] = useState(null);

    const { isSocketReady } = useSocket();

    useEffect(() => {
      if (initialized && keycloak) {
        axios.interceptors.request.use((config) => {
            if (keycloak) config.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
            return config;
        })
      }
    }, [initialized, keycloak]);

    useEffect(() => {
      setIsLoading(!(initialized && !userState.isLoading));
    }, [initialized, userState.isLoading])

    useEffect(() => {
      if (initialized) {
        if (!keycloak.authenticated) {
          setIsAuth(false);
        }
        else if (userState.isAuth !== null) {
          setIsAuth(userState.isAuth);
        }
      }
    }, [initialized, keycloak.authenticated, userState.isAuth])

    useEffect(() => {
      if (isAuth === false) {
        if (keycloak.authenticated) {
          keycloak.logout();
        }
        else {
          keycloak.login();
        }
      }
    }, [isAuth])

    useEffect(() => {
      if (userState.isError) {
        keycloak.logout();
      }
    }, [userState.isError])

    return (
      <>
      {
          userState.isError ?
          <div /> :
          isLoading ?
          <LoadingComponent />
          :
          isAuth === false ?
          <div />
          :
          !isSocketReady ?
          <LoadingComponent />
          :
          userState.hasConsent ?
          <HashRouter>
              <React.Suspense fallback={<LoadingComponent />}>
                  <div className='App'>
                      <Switch>
                          <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                          <Redirect to='/' />
                      </Switch>
                  </div>
              </React.Suspense>
          </HashRouter>
          :
          <Consent />
      }
      </>
    );
};

export default AppRouter;
