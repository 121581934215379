import axios from "axios";
import { COREAPI_ENDPOINTS } from "../../config";
import { IAddetto } from "../../types";
import { launchNotification } from ".";

export async function getAddetti() {
  return await axios.get(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ADDETTI);
}

export async function createAddetto(addetto: IAddetto) {
  try {
    await axios.post(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ADDETTI, addetto);
    launchNotification("SUCCESSO", "Addetto creato con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la creazione dell'addetto.", "danger");
  }
}

export async function updateAddetto(addetto: IAddetto) {
  try {
    await axios.put(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ADDETTI + "/" + addetto.id, addetto);
    launchNotification("SUCCESSO", "Addetto modificato con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante la modifica dell'addetto", "danger");
  }
}

export async function deleteAddetto(addetto: IAddetto) {
  try {
    await axios.delete(COREAPI_ENDPOINTS.URL + COREAPI_ENDPOINTS.PATH_ADDETTI + "/" + addetto.id);
    launchNotification("SUCCESSO", "Addetto eliminato con successo",  "success");
  } catch (err) {
    launchNotification("ERRORE", "Errore durante l'eliminazione dell'addetto", "danger");
  }
}
